import React from 'react';

import { Link } from 'gatsby';

import Fade from "react-reveal/Fade";

const categories = [
    {
        name: 'Belysning',
        description: 'Takkronor, bords- & golvlampor',
        image: 'belysning.png'
    },
    {
        name: 'Möbler',
        description: 'Soffor, fotöljer & bokyllor m.m.',
        image: 'mobler.png'
    },
    {
        name: 'Konsthantverk',
        description: 'Skulpturer',
        image: 'konsthantverk.png'
    },
    {
        name: 'Konst',
        description: 'Tavlor, ritade  tallrikar m.m.',
        image: 'konst.png'
    },
    {
        name: 'Silver',
        description: 'Gafflar, bestik, skedar & knivar m.m.',
        image: 'silver.png'
    },
    {
        name: 'Smycken, ur & preciosa',
        description: 'Smycken, ur & preciosa',
        image: 'diverse.png'
    },
    {
        name: 'Diverse',
        description: 'Blandade antikviteter',
        image: 'diverse.png'
    }
];

const ProductCategories = () => {
    return (
        <section className="categories">
            <div className="container">
                <div className="row">
                    <div className="col text-center">
                        <Fade up>
                            <h2>Kategorier</h2>
                        </Fade>
                    </div>
                </div>

                <div className="row">
                    {categories.map((category, index) => 
                        <div className="col-lg-6 mt-5" key={index}>
                            <Link to={`/kategori/${category.name.toLowerCase()}`}>
                                <div className="category">
                                    <div className="text">
                                        <div>
                                            <h2>{category.name}</h2>
                                            {category.description !== undefined && (
                                                <span className="description d-block">{category.description}</span>
                                            )}
                                            <button type="button" className="btn btn-light mt-4">Visa {category.name}</button>
                                        </div>
                                    </div>
                                    <img src={`/categories/${category.image}`} className="img-fluid w-100" alt="" />
                                </div>
                            </Link>
                        </div>
                    )}
                </div>

                {/* <div className="row mt-5">
                    <div className="col text-center">
                        <a href="#0" className="btn btn-primary">Visa alla kategorier</a>
                    </div>
                </div> */}
            </div>
            </section>
    );
};

export default ProductCategories;