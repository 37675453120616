import { Link } from 'gatsby';
import React, { useState } from 'react';

import number_format from '../helpers/numberformat'

import Fade from "react-reveal/Fade";

const ProductSlider = (props) => {
    const products = props.products.allPrismicProducts.edges;
    const [limit, setLimit] = useState(8);
    
    return (
        <section className="popular-products">
            <div className="container">
                <div className="row">
                    <div className="col text-center">
                        <Fade up>
                            <h2>{props.text}</h2>
                        </Fade>
                    </div>
                </div>
                {/* <Fade up> */}
                <div className="row mt-5">
                    {products.slice(0, props.limit == false ? 100000 : limit).map((product, index) => 
                    <div className="col-6 col-lg-3" key={index}>
                        <Link to={`/produkt/${product.node.uid}`}>
                            <div className="product">
                                <div className="product-image">
                                    {product.node.data.sold == true && <div className="sold">Sold</div>}
                                    <img src={product.node.data.product_images[0].image.url} alt="" className="img-fluid w-100" />
                                </div>
                                <div className="product-information pt-3">
                                    <h3 children={product.node.data.name.text} />
                                    <div className="product-category" children={product.node.data.category} />
                                    {parseInt(product.node.data.price) > 0 && (
                                        <div className="product-price" children={`${number_format(product.node.data.price, '', '', ' ')} kr`} />
                                    )}
                                </div>
                            </div>
                        </Link>
                    </div>
                    )}
                </div>
                {/* </Fade> */}
                {props.limit !== false && (
                <div className="row mt-5">
                    <div className="col text-center">
                        <button type="button" onClick={() => setLimit(limit + 4)} className="btn btn-primary">Visa fler produkter</button>
                    </div>
                </div>
                )}
            </div>
            </section>
    );
};

export default ProductSlider;