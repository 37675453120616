import React from "react"

import { useStaticQuery, graphql } from "gatsby"

import ContactForm from "../../components/contactform"
import ProductCategories from "../../components/productcategories"
import ProductSlider from "../../components/productslider"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Footer from "../../components/footer"
import Menu from "../../components/menu"

const FurnitureCategoryPage = () => {

  const productsQuery = useStaticQuery(graphql`
    {
      allPrismicProducts(filter: {data: {category: {eq: "Möbler"}}}) {
        edges {
          node {
            uid
            data {
              category
              description {
                text
              }
              price
              product_images {
                image {
                  url
                }
              }
              name {
                text
              }
              proveniens {
                text
              }
              sold
            }
          }
        }
      }
    }  
  `);

  return (
    <Layout>
      <SEO title="Möbler - Antikviteten.se" />
      
      <Menu top={true} />
      <ProductSlider text="Möbler" products={productsQuery} limit={false} />
      <ProductCategories />
      <ContactForm 
        text={{ 
          h2: 'Behov av värdering? – Svar inom 24h',
          h3: 'Oavset om det är en stor eller liten pjäs så hjälper vi till med värdering.'
        }} 
      />
      <Footer />
    </Layout>
  )
}

export default FurnitureCategoryPage;
